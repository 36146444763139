import { createRouter, createWebHistory } from 'vue-router';
import AllGames from '../views/AllGames.vue';
import GaMe1 from '../views/GaMe1.vue';
import GaMe2 from '../views/GaMe2.vue';
import GaMe3 from '../views/GaMe3.vue';
import GaMe4 from '../views/GaMe4.vue';
import GaMe5 from '../views/GaMe5.vue';
import GaMe6 from '../views/GaMe6.vue';
import GaMe7 from '../views/GaMe7.vue';
import GaMe8 from '../views/GaMe8.vue';
import GaMe9 from '../views/GaMe9.vue';
import GaMe10 from '../views/GaMe10.vue';
import GaMe11 from '../views/GaMe11.vue';
import GaMe12 from '../views/GaMe12.vue';
import GaMe13 from '../views/GaMe13.vue';
import GaMe14 from '../views/GaMe14.vue';
import GaMe15 from '../views/GaMe15.vue';
import GaMe16 from '../views/GaMe16.vue';
import GaMe17 from '../views/GaMe17.vue';
import GaMe18 from '../views/GaMe18.vue';
import GaMe19 from '../views/GaMe19.vue';
import GaMe20 from '../views/GaMe20.vue';
import GaMe21 from '../views/GaMe21.vue';
import GaMe22 from '../views/GaMe22.vue';
import GaMe23 from '../views/GaMe23.vue';
import GaMe24 from '../views/GaMe24.vue';
import GaMe25 from '../views/GaMe25.vue';
import GaMe26 from '../views/GaMe26.vue';
import GaMe27 from '../views/GaMe27.vue';
import GaMe28 from '../views/GaMe28.vue';
import GaMe29 from '../views/GaMe29.vue';
import GaMe30 from '../views/GaMe30.vue';
import GaMe31 from '../views/GaMe31.vue';
import GaMe32 from '../views/GaMe32.vue';
import GaMe33 from '../views/GaMe33.vue';
import GaMe34 from '../views/GaMe34.vue';
import GaMe35 from '../views/GaMe35.vue';
import GaMe36 from '../views/GaMe36.vue';
import GaMe37 from '../views/GaMe37.vue';
import GaMe38 from '../views/GaMe38.vue';
import GaMe39 from '../views/GaMe39.vue';
import GaMe40 from '../views/GaMe40.vue';
import GaMe41 from '../views/GaMe41.vue';
import GaMe42 from '../views/GaMe42.vue';
import GaMe43 from '../views/GaMe43.vue';
import GaMe44 from '../views/GaMe44.vue';
import GaMe45 from '../views/GaMe45.vue';
import GaMe46 from '../views/GaMe46.vue';
import GaMe47 from '../views/GaMe47.vue';
import GaMe48 from '../views/GaMe48.vue';
import GaMe49 from '../views/GaMe49.vue';
import GaMe50 from '../views/GaMe50.vue';
import GaMe51 from '../views/GaMe51.vue';
import GaMe52 from '../views/GaMe52.vue';
import GaMe53 from '../views/GaMe53.vue';
import GaMe54 from '../views/GaMe54.vue';
import GaMe55 from '../views/GaMe55.vue';
import GaMe56 from '../views/GaMe56.vue';
import GaMe57 from '../views/GaMe57.vue';
import GaMe58 from '../views/GaMe58.vue';
import GaMe59 from '../views/GaMe59.vue';
import GaMe60 from '../views/GaMe60.vue';
import GaMe61 from '../views/GaMe61.vue';
import GaMe62 from '../views/GaMe62.vue';
import GaMe63 from '../views/GaMe63.vue';
import GaMe64 from '../views/GaMe64.vue';
import GaMe65 from '../views/GaMe65.vue';
import GaMe66 from '../views/GaMe66.vue';
import GaMe67 from '../views/GaMe67.vue';
import GaMe68 from '../views/GaMe68.vue';
import GaMe69 from '../views/GaMe69.vue';
import GaMe70 from '../views/GaMe70.vue';
import GaMe71 from '../views/GaMe71.vue';
import GaMe72 from '../views/GaMe72.vue';
import GaMe73 from '../views/GaMe73.vue';
import GaMe74 from '../views/GaMe74.vue';
import GaMe75 from '../views/GaMe75.vue';
import GaMe76 from '../views/GaMe76.vue';
import GaMe77 from '../views/GaMe77.vue';
import GaMe78 from '../views/GaMe78.vue';
import GaMe79 from '../views/GaMe79.vue';
import GaMe80 from '../views/GaMe80.vue';
import GaMe81 from '../views/GaMe81.vue';
import GaMe82 from '../views/GaMe82.vue';
import GaMe83 from '../views/GaMe83.vue';
import GaMe84 from '../views/GaMe84.vue';
import GaMe85 from '../views/GaMe85.vue';
import GaMe86 from '../views/GaMe86.vue';
import GaMe87 from '../views/GaMe87.vue';
import GaMe88 from '../views/GaMe88.vue';
import GaMe89 from '../views/GaMe89.vue';
import GaMe90 from '../views/GaMe90.vue';
import GaMe91 from '../views/GaMe91.vue';
import GaMe92 from '../views/GaMe92.vue';
import GaMe93 from '../views/GaMe93.vue';
import GaMe94 from '../views/GaMe94.vue';
import GaMe95 from '../views/GaMe95.vue';
import GaMe96 from '../views/GaMe96.vue';
import GaMe97 from '../views/GaMe97.vue';
import GaMe98 from '../views/GaMe98.vue';
import GaMe99 from '../views/GaMe99.vue';
import GaMe100 from '../views/GaMe100.vue';
import GaMe101 from '../views/GaMe101.vue';
import GaMe102 from '../views/GaMe102.vue';
import GaMe103 from '../views/GaMe103.vue';
import GaMe104 from '../views/GaMe104.vue';
import GaMe105 from '../views/GaMe105.vue';
import GaMe106 from '../views/GaMe106.vue';
import GaMe107 from '../views/GaMe107.vue';
import GaMe108 from '../views/GaMe108.vue';
import GaMe109 from '../views/GaMe109.vue';
import GaMe110 from '../views/GaMe110.vue';
import GaMe111 from '../views/GaMe111.vue';
import GaMe112 from '../views/GaMe112.vue';
import GaMe113 from '../views/GaMe113.vue';
import GaMe114 from '../views/GaMe114.vue';
import GaMe115 from '../views/GaMe115.vue';
import GaMe116 from '../views/GaMe116.vue';
import GaMe117 from '../views/GaMe117.vue';
import GaMe118 from '../views/GaMe118.vue';
import GaMe119 from '../views/GaMe119.vue';
import GaMe120 from '../views/GaMe120.vue';
import GaMe121 from '../views/GaMe121.vue';
import GaMe122 from '../views/GaMe122.vue';
import GaMe123 from '../views/GaMe123.vue';
import GaMe124 from '../views/GaMe124.vue';
import GaMe125 from '../views/GaMe125.vue';
import GaMe126 from '../views/GaMe126.vue';
import GaMe127 from '../views/GaMe127.vue';
import GaMe128 from '../views/GaMe128.vue';
import GaMe129 from '../views/GaMe129.vue';
import GaMe130 from '../views/GaMe130.vue';
import GaMe131 from '../views/GaMe131.vue';
import GaMe132 from '../views/GaMe132.vue';
import GaMe133 from '../views/GaMe133.vue';
import GaMe134 from '../views/GaMe134.vue';
import GaMe135 from '../views/GaMe135.vue';
import GaMe136 from '../views/GaMe136.vue';
import GaMe137 from '../views/GaMe137.vue';
import GaMe138 from '../views/GaMe138.vue';
import GaMe139 from '../views/GaMe139.vue';
import GaMe140 from '../views/GaMe140.vue';
import GaMe141 from '../views/GaMe141.vue';
import GaMe142 from '../views/GaMe142.vue';
import GaMe143 from '../views/GaMe143.vue';
import GaMe144 from '../views/GaMe144.vue';
import GaMe145 from '../views/GaMe145.vue';
import GaMe146 from '../views/GaMe146.vue';
import GaMe147 from '../views/GaMe147.vue';
import GaMe148 from '../views/GaMe148.vue';
import GaMe149 from '../views/GaMe149.vue';
import GaMe150 from '../views/GaMe150.vue';
import GaMe151 from '../views/GaMe151.vue';
import GaMe152 from '../views/GaMe152.vue';
import GaMe153 from '../views/GaMe153.vue';
import GaMe154 from '../views/GaMe154.vue';
import GaMe155 from '../views/GaMe155.vue';
import GaMe156 from '../views/GaMe156.vue';
import GaMe157 from '../views/GaMe157.vue';
import GaMe158 from '../views/GaMe158.vue';
import GaMe159 from '../views/GaMe159.vue';
import GaMe160 from '../views/GaMe160.vue';
import GaMe161 from '../views/GaMe161.vue';
import GaMe162 from '../views/GaMe162.vue';
import GaMe163 from '../views/GaMe163.vue';
import GaMe164 from '../views/GaMe164.vue';
import GaMe165 from '../views/GaMe165.vue';
import GaMe166 from '../views/GaMe166.vue';
import GaMe167 from '../views/GaMe167.vue';
import GaMe168 from '../views/GaMe168.vue';
import GaMe169 from '../views/GaMe169.vue';
import GaMe170 from '../views/GaMe170.vue';
import GaMe171 from '../views/GaMe171.vue';
import GaMe172 from '../views/GaMe172.vue';
import GaMe173 from '../views/GaMe173.vue';
import GaMe174 from '../views/GaMe174.vue';
import GaMe175 from '../views/GaMe175.vue';
import GaMe176 from '../views/GaMe176.vue';
import GaMe177 from '../views/GaMe177.vue';
import GaMe178 from '../views/GaMe178.vue';
import GaMe179 from '../views/GaMe179.vue';
import GaMe180 from '../views/GaMe180.vue';
const routes = [
  {
    path: '/',
    name: 'AllGames',
    component: AllGames,
  },
{path: '/game1', name: 'GaMe1', component: GaMe1},
{path: '/game2', name: 'GaMe2', component: GaMe2},
{path: '/game3', name: 'GaMe3', component: GaMe3},
{path: '/game4', name: 'GaMe4', component: GaMe4},
{path: '/game5', name: 'GaMe5', component: GaMe5},
{path: '/game6', name: 'GaMe6', component: GaMe6},
{path: '/game7', name: 'GaMe7', component: GaMe7},
{path: '/game8', name: 'GaMe8', component: GaMe8},
{path: '/game9', name: 'GaMe9', component: GaMe9},
{path: '/game10', name: 'GaMe10', component: GaMe10},
{path: '/game11', name: 'GaMe11', component: GaMe11},
{path: '/game12', name: 'GaMe12', component: GaMe12},
{path: '/game13', name: 'GaMe13', component: GaMe13},
{path: '/game14', name: 'GaMe14', component: GaMe14},
{path: '/game15', name: 'GaMe15', component: GaMe15},
{path: '/game16', name: 'GaMe16', component: GaMe16},
{path: '/game17', name: 'GaMe17', component: GaMe17},
{path: '/game18', name: 'GaMe18', component: GaMe18},
{path: '/game19', name: 'GaMe19', component: GaMe19},
{path: '/game20', name: 'GaMe20', component: GaMe20},
{path: '/game21', name: 'GaMe21', component: GaMe21},
{path: '/game22', name: 'GaMe22', component: GaMe22},
{path: '/game23', name: 'GaMe23', component: GaMe23},
{path: '/game24', name: 'GaMe24', component: GaMe24},
{path: '/game25', name: 'GaMe25', component: GaMe25},
{path: '/game26', name: 'GaMe26', component: GaMe26},
{path: '/game27', name: 'GaMe27', component: GaMe27},
{path: '/game28', name: 'GaMe28', component: GaMe28},
{path: '/game29', name: 'GaMe29', component: GaMe29},
{path: '/game30', name: 'GaMe30', component: GaMe30},
{path: '/game31', name: 'GaMe31', component: GaMe31},
{path: '/game32', name: 'GaMe32', component: GaMe32},
{path: '/game33', name: 'GaMe33', component: GaMe33},
{path: '/game34', name: 'GaMe34', component: GaMe34},
{path: '/game35', name: 'GaMe35', component: GaMe35},
{path: '/game36', name: 'GaMe36', component: GaMe36},
{path: '/game37', name: 'GaMe37', component: GaMe37},
{path: '/game38', name: 'GaMe38', component: GaMe38},
{path: '/game39', name: 'GaMe39', component: GaMe39},
{path: '/game40', name: 'GaMe40', component: GaMe40},
{path: '/game41', name: 'GaMe41', component: GaMe41},
{path: '/game42', name: 'GaMe42', component: GaMe42},
{path: '/game43', name: 'GaMe43', component: GaMe43},
{path: '/game44', name: 'GaMe44', component: GaMe44},
{path: '/game45', name: 'GaMe45', component: GaMe45},
{path: '/game46', name: 'GaMe46', component: GaMe46},
{path: '/game47', name: 'GaMe47', component: GaMe47},
{path: '/game48', name: 'GaMe48', component: GaMe48},
{path: '/game49', name: 'GaMe49', component: GaMe49},
{path: '/game50', name: 'GaMe50', component: GaMe50},
{path: '/game51', name: 'GaMe51', component: GaMe51},
{path: '/game52', name: 'GaMe52', component: GaMe52},
{path: '/game53', name: 'GaMe53', component: GaMe53},
{path: '/game54', name: 'GaMe54', component: GaMe54},
{path: '/game55', name: 'GaMe55', component: GaMe55},
{path: '/game56', name: 'GaMe56', component: GaMe56},
{path: '/game57', name: 'GaMe57', component: GaMe57},
{path: '/game58', name: 'GaMe58', component: GaMe58},
{path: '/game59', name: 'GaMe59', component: GaMe59},
{path: '/game60', name: 'GaMe60', component: GaMe60},
{path: '/game61', name: 'GaMe61', component: GaMe61},
{path: '/game62', name: 'GaMe62', component: GaMe62},
{path: '/game63', name: 'GaMe63', component: GaMe63},
{path: '/game64', name: 'GaMe64', component: GaMe64},
{path: '/game65', name: 'GaMe65', component: GaMe65},
{path: '/game66', name: 'GaMe66', component: GaMe66},
{path: '/game67', name: 'GaMe67', component: GaMe67},
{path: '/game68', name: 'GaMe68', component: GaMe68},
{path: '/game69', name: 'GaMe69', component: GaMe69},
{path: '/game70', name: 'GaMe70', component: GaMe70},
{path: '/game71', name: 'GaMe71', component: GaMe71},
{path: '/game72', name: 'GaMe72', component: GaMe72},
{path: '/game73', name: 'GaMe73', component: GaMe73},
{path: '/game74', name: 'GaMe74', component: GaMe74},
{path: '/game75', name: 'GaMe75', component: GaMe75},
{path: '/game76', name: 'GaMe76', component: GaMe76},
{path: '/game77', name: 'GaMe77', component: GaMe77},
{path: '/game78', name: 'GaMe78', component: GaMe78},
{path: '/game79', name: 'GaMe79', component: GaMe79},
{path: '/game80', name: 'GaMe80', component: GaMe80},
{path: '/game81', name: 'GaMe81', component: GaMe81},
{path: '/game82', name: 'GaMe82', component: GaMe82},
{path: '/game83', name: 'GaMe83', component: GaMe83},
{path: '/game84', name: 'GaMe84', component: GaMe84},
{path: '/game85', name: 'GaMe85', component: GaMe85},
{path: '/game86', name: 'GaMe86', component: GaMe86},
{path: '/game87', name: 'GaMe87', component: GaMe87},
{path: '/game88', name: 'GaMe88', component: GaMe88},
{path: '/game89', name: 'GaMe89', component: GaMe89},
{path: '/game90', name: 'GaMe90', component: GaMe90},
{path: '/game91', name: 'GaMe91', component: GaMe91},
{path: '/game92', name: 'GaMe92', component: GaMe92},
{path: '/game93', name: 'GaMe93', component: GaMe93},
{path: '/game94', name: 'GaMe94', component: GaMe94},
{path: '/game95', name: 'GaMe95', component: GaMe95},
{path: '/game96', name: 'GaMe96', component: GaMe96},
{path: '/game97', name: 'GaMe97', component: GaMe97},
{path: '/game98', name: 'GaMe98', component: GaMe98},
{path: '/game99', name: 'GaMe99', component: GaMe99},
{path: '/game100', name: 'GaMe100', component: GaMe100},
{path: '/game101', name: 'GaMe101', component: GaMe101},
{path: '/game102', name: 'GaMe102', component: GaMe102},
{path: '/game103', name: 'GaMe103', component: GaMe103},
{path: '/game104', name: 'GaMe104', component: GaMe104},
{path: '/game105', name: 'GaMe105', component: GaMe105},
{path: '/game106', name: 'GaMe106', component: GaMe106},
{path: '/game107', name: 'GaMe107', component: GaMe107},
{path: '/game108', name: 'GaMe108', component: GaMe108},
{path: '/game109', name: 'GaMe109', component: GaMe109},
{path: '/game110', name: 'GaMe110', component: GaMe110},
{path: '/game111', name: 'GaMe111', component: GaMe111},
{path: '/game112', name: 'GaMe112', component: GaMe112},
{path: '/game113', name: 'GaMe113', component: GaMe113},
{path: '/game114', name: 'GaMe114', component: GaMe114},
{path: '/game115', name: 'GaMe115', component: GaMe115},
{path: '/game116', name: 'GaMe116', component: GaMe116},
{path: '/game117', name: 'GaMe117', component: GaMe117},
{path: '/game118', name: 'GaMe118', component: GaMe118},
{path: '/game119', name: 'GaMe119', component: GaMe119},
{path: '/game120', name: 'GaMe120', component: GaMe120},
{path: '/game121', name: 'GaMe121', component: GaMe121},
{path: '/game122', name: 'GaMe122', component: GaMe122},
{path: '/game123', name: 'GaMe123', component: GaMe123},
{path: '/game124', name: 'GaMe124', component: GaMe124},
{path: '/game125', name: 'GaMe125', component: GaMe125},
{path: '/game126', name: 'GaMe126', component: GaMe126},
{path: '/game127', name: 'GaMe127', component: GaMe127},
{path: '/game128', name: 'GaMe128', component: GaMe128},
{path: '/game129', name: 'GaMe129', component: GaMe129},
{path: '/game130', name: 'GaMe130', component: GaMe130},
{path: '/game131', name: 'GaMe131', component: GaMe131},
{path: '/game132', name: 'GaMe132', component: GaMe132},
{path: '/game133', name: 'GaMe133', component: GaMe133},
{path: '/game134', name: 'GaMe134', component: GaMe134},
{path: '/game135', name: 'GaMe135', component: GaMe135},
{path: '/game136', name: 'GaMe136', component: GaMe136},
{path: '/game137', name: 'GaMe137', component: GaMe137},
{path: '/game138', name: 'GaMe138', component: GaMe138},
{path: '/game139', name: 'GaMe139', component: GaMe139},
{path: '/game140', name: 'GaMe140', component: GaMe140},
{path: '/game141', name: 'GaMe141', component: GaMe141},
{path: '/game142', name: 'GaMe142', component: GaMe142},
{path: '/game143', name: 'GaMe143', component: GaMe143},
{path: '/game144', name: 'GaMe144', component: GaMe144},
{path: '/game145', name: 'GaMe145', component: GaMe145},
{path: '/game146', name: 'GaMe146', component: GaMe146},
{path: '/game147', name: 'GaMe147', component: GaMe147},
{path: '/game148', name: 'GaMe148', component: GaMe148},
{path: '/game149', name: 'GaMe149', component: GaMe149},
{path: '/game150', name: 'GaMe150', component: GaMe150},
{path: '/game151', name: 'GaMe151', component: GaMe151},
{path: '/game152', name: 'GaMe152', component: GaMe152},
{path: '/game153', name: 'GaMe153', component: GaMe153},
{path: '/game154', name: 'GaMe154', component: GaMe154},
{path: '/game155', name: 'GaMe155', component: GaMe155},
{path: '/game156', name: 'GaMe156', component: GaMe156},
{path: '/game157', name: 'GaMe157', component: GaMe157},
{path: '/game158', name: 'GaMe158', component: GaMe158},
{path: '/game159', name: 'GaMe159', component: GaMe159},
{path: '/game160', name: 'GaMe160', component: GaMe160},
{path: '/game161', name: 'GaMe161', component: GaMe161},
{path: '/game162', name: 'GaMe162', component: GaMe162},
{path: '/game163', name: 'GaMe163', component: GaMe163},
{path: '/game164', name: 'GaMe164', component: GaMe164},
{path: '/game165', name: 'GaMe165', component: GaMe165},
{path: '/game166', name: 'GaMe166', component: GaMe166},
{path: '/game167', name: 'GaMe167', component: GaMe167},
{path: '/game168', name: 'GaMe168', component: GaMe168},
{path: '/game169', name: 'GaMe169', component: GaMe169},
{path: '/game170', name: 'GaMe170', component: GaMe170},
{path: '/game171', name: 'GaMe171', component: GaMe171},
{path: '/game172', name: 'GaMe172', component: GaMe172},
{path: '/game173', name: 'GaMe173', component: GaMe173},
{path: '/game174', name: 'GaMe174', component: GaMe174},
{path: '/game175', name: 'GaMe175', component: GaMe175},
{path: '/game176', name: 'GaMe176', component: GaMe176},
{path: '/game177', name: 'GaMe177', component: GaMe177},
{path: '/game178', name: 'GaMe178', component: GaMe178},
{path: '/game179', name: 'GaMe179', component: GaMe179},
{path: '/game180', name: 'GaMe180', component: GaMe180},
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
